import dynamic from 'next/dynamic';

import { DocTypeMap, TemplateComponent, TemplateType } from '@apps/web/components/templates/types';

export const templateMap: {
	[Key in TemplateType]: TemplateComponent<DocTypeMap[Key]>;
} = {
	frontpage: dynamic(import('./frontpage')),
	page: dynamic(import('./page')),
	newsArticlePage: dynamic(import('./newsArticlePage')),
	glossaryPage: dynamic(import('./glossaryPage')),
	newsOverviewPage: dynamic(import('./newsOverviewPage')),
	transportPage: dynamic(import('./transportPage')),
};
