/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import { tailwindConfig } from '@canify/theme';
import { Button } from '@canify/ui-button';
import { CloseIcon } from '@canify/ui-icon';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

export type DialogProps = MuiDialogProps & {
	onClose?: () => void;
	dontCloseOnBackdropClick?: boolean;
	disableBackgroundScroll?: boolean;
};

export const Dialog = ({ onClose, dontCloseOnBackdropClick, children, disableBackgroundScroll, ...props }: DialogProps) => {
	return (
		<MuiDialog
			onClose={(_, reason) => {
				if (reason === 'backdropClick' && dontCloseOnBackdropClick) {
					return;
				}

				onClose?.();
			}}
			// There is in MUI that sometimes the scrollbar isn't restored when Dialog is closed
			onBackdropClick={() => {
				if (!dontCloseOnBackdropClick && onClose) {
					onClose();
				}
			}}
			// There is in MUI that sometimes the scrollbar isn't restored when modal is closed
			disableScrollLock={!disableBackgroundScroll}
			sx={{
				'& .MuiDialog-paper': {
					padding: '16px 40px 28px 40px',
					[`@media (max-width:${tailwindConfig.theme.screens.sm})`]: {
						padding: '16px 16px 28px 16px',
						margin: '32px 16px',
						wordBreak: 'break-word',
					},
				},
			}}
			{...props}
		>
			{onClose && (
				<span className="absolute top-0 right-0">
					<Button color="action-blue" size="large" type="text" onClick={onClose} iconOnly={CloseIcon} />
				</span>
			)}
			{children}
		</MuiDialog>
	);
};

export type UseDialogParam = {
	initialState?: boolean;
	onClose?: () => void;
	onOpen?: () => void;
};

export const useDialog = (config?: UseDialogParam) => {
	const [isOpen, setOpenState] = useState(config?.initialState ?? false);

	const setOpen = useCallback(() => {
		setOpenState(true);
		config?.onOpen?.();
	}, []);

	const setClose = useCallback(() => {
		setOpenState(false);
		config?.onClose?.();
	}, []);

	const setToggle = useCallback(() => {
		if (isOpen) {
			setClose();
			return;
		}

		setOpen();
	}, [isOpen]);

	return {
		isOpen,
		setOpen,
		setClose,
		setToggle,
		setOpenState,
	};
};

export type UseDialogReturn = ReturnType<typeof useDialog>;

Dialog.useDialog = useDialog;
