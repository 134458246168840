import { useRouter } from 'next/router';
import { useEffect } from 'react';

const getUrl = (url: string | string[]) => {
	if (Array.isArray(url)) {
		return url.join('/').replace('//', '/');
	}

	return url.replace('//', '/');
};

/**
 * This reads the extension from the URL, and conditional redirects to the correct language.
 */
export const useUrlExtensionRedirect = (
	/**
	 * A record of locales and their redirects.
	 * You can either pass a callback `function` or a `string` or `string[]`.
	 *
	 * If you pass a string it will automatically redirect to that url.
	 *
	 * @see {@link getUrl}
	 *
	 *  @example
	 *  ```tsx
	 *  useLocaleRedirect({
	 *    de: ['de', router.asPath],
	 *    localhost: () => console.log('localhost),
	 *  });
	 *  ```
	 */
	extensions: Record<string, Parameters<typeof getUrl>[0] | (() => void)>,
) => {
	const router = useRouter();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const { hostname } = window.location;

			const extensionsplitted = hostname.split('.');

			const hostNameExtension = extensionsplitted[extensionsplitted.length - 1];

			if (extensions?.[hostNameExtension]) {
				const callbackOrUrl = extensions[hostNameExtension];

				if (callbackOrUrl instanceof Function) {
					callbackOrUrl?.();
					return;
				}

				router.replace(getUrl(callbackOrUrl));
			}
		}

		return () => {};
	}, []);
};
