import createImageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { createCurrentUserHook, createPreviewSubscriptionHook } from 'next-sanity';

import { TemplateType } from '@apps/web/components/templates/types';
import { PortableText as PortableTextComponent } from '@portabletext/react';

import { config } from '../config';

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 * */
export const urlFor = (source: SanityImageSource) =>
	createImageUrlBuilder(config).image(source).auto('format');

/**
 * Helper function for
 */
export const getFileUrl = (asset: { _ref: string }) => {
	// Example ref: file-207fd9951e759130053d37cf0a558ffe84ddd1c9-mp3
	// We don't need the first part, unless we're using the same function for files and images
	const [, id, extension] = asset._ref.split('-');

	return `https://cdn.sanity.io/files/${config.projectId}/${config.dataset}/${id}.${extension}`;
};

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook({
	projectId: config.projectId,
	dataset: config.dataset,
});

// Set up Portable Text serialization
export const PortableText = (props) => <PortableTextComponent components={{}} {...props} />;

export function filterDataToSingleItem<T extends { _id: string }>(data: T | T[], preview = false): T {
	if (!Array.isArray(data)) {
		return data;
	}

	if (data.length === 1) {
		return data[0];
	}

	if (preview) {
		return data.find((item) => item._id.startsWith('drafts.')) || data[0];
	}

	return data[0];
}

// Helper function for using the current logged in user account
export const useCurrentUser = createCurrentUserHook(config);

export function buildLinkForEdit(
	docType: TemplateType | undefined,
	pageId: string | undefined,
): string | undefined {
	if (!docType || !pageId) return undefined;

	const docTypeToSanityId: Record<TemplateType, string> = {
		frontpage: 'frontpage',
		page: 'page',
		newsArticlePage: 'newsArticlePage',
		glossaryPage: 'glossaryPage',
		newsOverviewPage: 'newsOverviewPage',
		transportPage: 'navigation',
	};

	if (!docTypeToSanityId[docType]) {
		return undefined;
	}

	return `${config.studioUrl}/desk/${docTypeToSanityId[docType]}${
		pageId !== 'frontpage' ? `;${pageId}` : ''
	}`;
}
