import { tailwindConfig } from '@canify/theme';
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';

type DialogTitleProps = MuiDialogTitleProps;

export const DialogTitle = (props: DialogTitleProps) => {
	return (
		<MuiDialogTitle
			sx={{
				'&.MuiTypography-root': {
					fontFamily: `${tailwindConfig.theme.fontFamily.display} !important`,
					fontSize: tailwindConfig.theme.fontSize['desktop-body-large'],
					fontWeight: 900,
					textAlign: 'center',
					color: tailwindConfig.theme.colors['brand-blue'].DEFAULT,
					[`@media (max-width:${tailwindConfig.theme.screens.sm})`]: {
						fontSize: tailwindConfig.theme.fontSize['mobile-h4'],
					},
				},
			}}
			{...props}
		/>
	);
};
