import Link from 'next/link';
import { useRouter } from 'next/router';
import { NextApiRequest, NextApiResponse } from 'next/types';
import { useState } from 'react';

import { Dialog } from '@canify/ui-dialog';
import { Button } from '@canify/ui-button';
import { LogoutIcon, PenIcon } from '@canify/ui-icon';
import { CircularProgress } from '@mui/material';

import { TrashIcon } from '@libs/ui/icon/src/lib/trash';

import styles from './preview.module.scss';

export const Preview = ({ editUrl, redeployUrl }: { editUrl?: string; redeployUrl?: string }) => {
	const { asPath } = useRouter();
	const [loading, setLoading] = useState(false);
	const dialog = Dialog.useDialog();

	const triggerRedeploy = async () => {
		if (!redeployUrl) {
			return;
		}

		try {
			setLoading(true);
			const res = await fetch(redeployUrl);

			if (res.status === 201 || res.status === 200) {
				// eslint-disable-next-line no-console
				console.info('Success - Trigger Redeploy');
			}

			return;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error - Trigger Redeploy', error);
		} finally {
			setLoading(false);
			dialog.setClose();
		}
	};

	return (
		<>
			<div className={styles.container}>
				<ul className={styles.ul}>
					<li>
						<Link href={`/api/exit-preview?slug=${asPath}`}>
							<a className={styles.button} data-exit>
								<LogoutIcon />
								Exit Preview
							</a>
						</Link>
					</li>
					{redeployUrl && (
						<li>
							<button className={styles.button} onClick={dialog.setOpen} data-clean>
								<TrashIcon />
								Clean cache
							</button>
						</li>
					)}
					{editUrl && (
						<li>
							<Link href={editUrl}>
								<a className={styles.button} target="_blank" data-edit>
									<PenIcon />
									Edit in Studio
								</a>
							</Link>
						</li>
					)}
				</ul>
			</div>

			<Dialog open={dialog.isOpen} onClose={dialog.setClose}>
				<h5 className="text-desktop-h4 mt-4">Clean cache</h5>

				<p className="text-body-medium mt-2">
					This will trigger a rebuild of all pages. This might take some time to take affect
				</p>

				<div className="flex gap-x-2 mt-4">
					<Button onClick={triggerRedeploy}>
						Clean {loading && <CircularProgress className="ml-2" size={20} color="inherit" />}
					</Button>
					<Button onClick={dialog.setClose} type="outlined">
						Cancel
					</Button>
				</div>
			</Dialog>
		</>
	);
};

export const exitPreview = (req: NextApiRequest, res: NextApiResponse) => {
	res.clearPreviewData();

	res.writeHead(307, { Location: req?.query?.slug ?? '/' });

	return res.end();
};
