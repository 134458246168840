import { GetStaticPathsContext, GetStaticPropsContext, GetStaticPropsResult } from 'next';
import ErrorPage from 'next/error';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { groq } from 'next-sanity';
import { NextSeo } from 'next-seo';
import React from 'react';

import { config } from '@apps/web/lib/config';
import { footerExpansion, navigationExpansion } from '@apps/web/lib/sanity/groqExpansions';
import { FooterType, NavigationType } from '@apps/web/lib/sanity/types';
import { Preview } from '@canify/studio-ui';
import { useUrlExtensionRedirect } from '@canify/utils-routing';

import { templateMap } from '../components/templates';
import {
	docQuery,
	DocTypeMap,
	TemplateComponent,
	templates,
	TemplateType,
} from '../components/templates/types';
import { buildLinkForEdit, filterDataToSingleItem, usePreviewSubscription } from '../lib/sanity/sanity';
import { getClient } from '../lib/sanity/sanity.server';

export default function Page({ data, preview }: { data: ResolvedData; preview: boolean }) {
	const router = useRouter();
	useUrlExtensionRedirect({
		de: ['de', router.asPath],
	});


	const { data: previewPageData } = usePreviewSubscription<DocTypeMap[typeof docType]>(data.query, {
		params: data?.queryParams ?? {},
		initialData: data?.pageData,
		enabled: preview,
	});

	const pageData = filterDataToSingleItem(previewPageData, preview);

	if (!pageData) {
		return null;
	}

	if (!router.isFallback && !data.pageData?.slug) {
		return <ErrorPage statusCode={404} />;
	}

	const { docType } = data;
	const Component = templateMap[docType] as TemplateComponent<DocTypeMap[typeof docType]>;
	const { metaTitle, metaDescription, image } = data?.pageData?.seo ?? {};
	const { url } = image?.asset ?? {};

	return (
		<>
			<Head>
				<NextSeo
					title={metaTitle ?? ''}
					description={metaDescription ?? ''}
					openGraph={{
						description: metaDescription,
						images: url ? [{ url }] : [{ url: '' }],
					}}
				/>
			</Head>
			<Component navigation={data.navigation} footer={data.footer} pageData={pageData} />
			{preview && (
				<Preview editUrl={buildLinkForEdit(docType, pageData._id)} redeployUrl={config.deployUrl} />
			)}
		</>
	);
}

enum TransportPageSlug {
	Patient = 'patient',
	MedicalCannabis = 'medical-cannabis',
	About = 'about',
}

export async function getStaticPaths({ locales }: GetStaticPathsContext) {
	const client = getClient(false);

	const pageQueries = await client.fetch<
		{
			slug: Exclude<DocTypeMap[TemplateType]['slug'], undefined>['current'];
			lang: string;
		}[]
	>(
		groq`*[_type in [${templates.map((e) => `"${e}"`).join(',')}] && defined(slug.current)]{
      "slug": slug.current,
      "lang": _lang
    }`,
	);

	// Split the slug strings to arrays (as required by Next.js)
	const paths = pageQueries.reduce((collector, { slug, lang }) => {
		const slugParam = slug?.split('/').filter((p) => p);

		if (slugParam.length === 1 && ['en', 'de'].includes(slugParam[0])) {
			return collector;
		}

		if (lang) {
			collector.push({
				params: { slug: slugParam },
				locale: lang,
			});
		} else if (locales) {
			for (const locale of locales) {
				collector.push({
					params: { slug: slugParam },
					locale,
				});
			}
		}

		return collector;
	}, [] as { params: { slug: string[] }; locale: string }[]);

	const navigationEnglish = await client.fetch<Sanity.Schema.Navigation>(
		groq`*[_type == "navigation" && _lang == "en"][0]`,
	);
	const navigationGerman = await client.fetch<Sanity.Schema.Navigation>(
		groq`*[_type == "navigation" && _lang == "de"][0]`,
	);

	const transportPagesPathsEn = getTransportPagesPaths(navigationEnglish);
	const transportPagesPathsDe = getTransportPagesPaths(navigationGerman);

	paths.concat(transportPagesPathsEn).concat(transportPagesPathsDe);

	return { paths, fallback: 'blocking' };
}

interface ResolvedData<Type extends TemplateType = TemplateType> {
	query: string;
	queryParams: { slug?: string };
	docType: Type;
	pageData: DocTypeMap[Type];
	globalSettings: Sanity.Schema.GlobalSettings;
	footer: FooterType;
	navigation: NavigationType;
}

export async function getStaticProps(ctx: GetStaticPropsContext<{ slug: string[] }>): Promise<
	GetStaticPropsResult<{
		translations: Record<string, string>;
		data: ResolvedData;
		preview: boolean;
	}>
> {
	const { params, locale, preview = false } = ctx;
	const client = getClient(preview);

	// Every website has a bunch of global content that every page needs, too!
	const globalSettings = await client.fetch<Sanity.Schema.GlobalSettings>(
		groq`*[_type == "globalSettings"][0]`,
	);

	const footer = await client.fetch<FooterType>(
		groq`*[_type == "footer" && _lang == "${locale}"][0]  {${footerExpansion}}`,
	);

	const navigation = await client.fetch<NavigationType>(
		groq`*[_type == "navigation" && _lang == "${locale}"][0] {${navigationExpansion}}`,
	);

	// A helper function to work out what query we should run based on this slug
	const { query, queryParams, docType } = getQueryFromSlug(params?.slug, locale, navigation);
	const { messages: translations } = await import(`../translations/locales/${locale}/messages.po`);

	// Get the initial data for this page, using the correct query
	const pageData = await client
		.fetch<DocTypeMap[typeof docType]>(query, queryParams)
		.then((results) => filterDataToSingleItem(results, preview));

	// if there isn't a slug on the pageData, it is a transport page and the slug must be set manually
	if (!pageData?.slug && queryParams?.slug) {
		if (pageData) {
			pageData.slug = { current: queryParams.slug.replace('/', '') };
		}
	}

	if (!pageData) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			translations,
			data: {
				query,
				queryParams,
				docType,
				pageData,
				globalSettings,
				footer,
				navigation,
			},
			preview,
		},
		// Read https://www.sanity.io/docs/api-cdn#da702944cd86 for more info
		revalidate: 61, // In seconds
	};
}

function getQueryFromSlug(
	slugArray: string[] = [],
	locale?: string,
	navigation?: NavigationType,
): {
	docType: TemplateType;
	queryParams: { slug?: string; locale: string | undefined };
	query: string;
} {
	if (slugArray.length === 0) {
		return {
			docType: 'frontpage',
			queryParams: { slug: '/', locale },
			query: docQuery.frontpage,
		};
	}

	const [slugStart] = slugArray;
	let docType: keyof typeof docQuery;
	const transportPageNavigation = navigation?.navigation?.map((page) => page.slug.replace('/', ''));
	// We now have to re-combine the slug array to match our slug in Sanity.
	const queryParams = { slug: `/${slugArray.join('/')}`, locale };

	// Keep extending this section to match the slug against the docQuery object keys
	if (docQuery[slugStart as TemplateType]) {
		docType = slugStart as TemplateType;
	} else if (slugStart === 'news') {
		if (slugArray.length === 1) {
			docType = 'newsOverviewPage';
		} else {
			docType = 'newsArticlePage';
		}
	} else if (slugStart === 'glossary' && slugArray.length === 1) {
		docType = 'glossaryPage';
	} else if (
		slugArray.length === 1 &&
		transportPageNavigation?.includes(slugStart as TransportPageSlug)
	) {
		docType = 'transportPage';
	} else {
		docType = 'page';
	}

	return {
		docType,
		queryParams,
		query: docQuery[docType],
	};
}

function getTransportPagesPaths(navigation: Sanity.Schema.Navigation & { _lang?: string }) {
	const transportPageNavigation = navigation.navigation;
	const paths: { params: { slug: string[] }; locale: string }[] = [];
	if (!navigation._lang) {
		throw new Error('There is now _lang in navigation document');
	}

	transportPageNavigation?.forEach((page) => {
		paths.push({
			locale: navigation._lang,
			params: {
				slug: [page.slug.current],
			},
		});
	});

	return paths;
}
